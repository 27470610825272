<template>
    <div>屏蔽</div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>

</style>